<template>
  <div class="w-full h-screen flex flex-col items-center justify-center p-4 md:p-8">
    <div class="max-w-2xl mx-auto text-center">
      <img
        src="/src/assets/img/gifs/black-cat.gif"
        alt="Cat waving goodbye"
        class="w-100 md:w-100 mx-auto mb-8"
      />

      <h1 class="font-fraunces text-3xl md:text-4xl lg:text-5xl font-thin mb-6">
        This site has moved!
      </h1>

      <p class="text-lg md:text-xl mb-8 text-gray-700">
        Please visit our new page at:
      </p>

      <a
        href="https://marketpedia.ca/labs?utm_source=play"
        target="_blank"
        class="inline-flex items-center gap-2 text-adviz-primary hover:underline text-lg md:text-xl font-medium"
      >
        marketpedia.ca/labs
        <img
          src="@/assets/img/icons/arrow-right-green.svg"
          alt="arrow right"
          width="12"
          class="mt-1"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomePlayClosed'
}
</script>
